import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import queryAllAppsImages from 'src/queries/queryAllAppsImages.js'
import App from 'src/components/App/App'
import Head from 'src/components/Head/Head'
import Header from 'src/components/Header/Header'
import Button from 'src/components/Button/Button'
import Paper from '../components/Paper/Paper'
import styles from './products.module.scss'

const Products = () => {
  const nodes = queryAllAppsImages()

  return (
    <App>
      <Head />
      <Header pageTitle="Products" />
      <main className={styles.main}>
        <section className={styles.content}>
          <div className={styles.productImageWrapper}>
            <Paper>
              <a href="https://piccollage.com" target="_blank">
                <Img
                  fluid={nodes.piccollageImg}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className={styles.product}
                />
              </a>
            </Paper>
          </div>
          <article className={styles.productInfoWrapper}>
            <header className={styles.infoHeader}>
              <a
                className={styles.headerLink}
                href="https://piccollage.com"
                target="_blank"
              >
                <Img
                  fluid={nodes.piccollageIcon}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className={styles.logo}
                />
                <h3 className={styles.title}>PicCollage</h3>
              </a>
            </header>
            <div className={styles.descWrapper}>
              <p className={styles.desc}>
                PicCollage is our top app that has been downloaded by over 200
                million people. Get inspired and create colorful collages, cards
                and stories to share what you love!
              </p>
            </div>
            <div className={styles.btnWrapper}>
              <div className={styles.downloadBtns}>
                <a
                  href="https://apps.apple.com/app/id448639966"
                  target="_blank"
                  className={styles.btnLink}
                >
                  <Img
                    fixed={nodes.downloadIos}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className={styles.downloadBtn}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.cardinalblue.piccollage.google"
                  target="_blank"
                  className={styles.btnLink}
                >
                  <Img
                    fixed={nodes.downloadAndroid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className={styles.downloadBtn}
                  />
                </a>
                <a
                  href="https://www.microsoft.com/en-gb/p/pic-collage/9nblggh34kdv?activetab=pivot:overviewtab"
                  target="_blank"
                  className={styles.btnLink}
                >
                  <Img
                    fixed={nodes.downloadWin}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className={styles.downloadBtn}
                  />
                </a>
              </div>
              <div className={styles.linkBtns}>
                <a
                  href="https://piccollage.com/"
                  target="_blank"
                  className={styles.btnLink}
                >
                  <Button>See website</Button>
                </a>
              </div>
            </div>
          </article>
        </section>
        <section className={styles.content}>
          <div className={styles.productImageWrapper}>
            <Paper>
              <a href="https://deckthecard.com" target="_blank">
                <Img
                  fluid={nodes.deckthecardImg}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className={styles.product}
                />
              </a>
            </Paper>
          </div>
          <article className={styles.productInfoWrapper}>
            <header className={styles.infoHeader}>
              <a
                className={styles.headerLink}
                href="https://deckthecard.com"
                target="_blank"
              >
                <Img
                  fluid={nodes.deckthecardIcon}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className={styles.logo}
                />
                <h3 className={styles.title}>Deck the Card</h3>
              </a>
            </header>
            <div className={styles.descWrapper}>
              <p className={styles.desc}>
                Deck the Card is a web app that lets everyone create an
                illustrated family holiday card with stickers and sweet,
                seasonal messages ready to be added and shared.
              </p>
            </div>
            <div className={styles.btnWrapper}>
              <div className={styles.linkBtns}>
                <a
                  href="https://deckthecard.com"
                  target="_blank"
                  className={styles.btnLink}
                >
                  <Button>See website</Button>
                </a>
              </div>
            </div>
          </article>
        </section>
        <section className={styles.content}>
          <div className={styles.productImageWrapper}>
            <Paper>
              <a
                href="https://apps.apple.com/us/app/vibes-video-editor-piccollage/id1444385543"
                target="_blank"
              >
                <Img
                  fluid={nodes.vibesImg}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className={styles.product}
                />
              </a>
            </Paper>
          </div>
          <article className={styles.productInfoWrapper}>
            <header className={styles.infoHeader}>
              <a
                href="https://apps.apple.com/us/app/vibes-video-editor-piccollage/id1444385543"
                target="_blank"
                className={styles.headerLink}
              >
                <Img
                  fluid={nodes.vibesIcon}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className={styles.logo}
                />
                <h3 className={styles.title}>Vibes</h3>
              </a>
            </header>
            <div className={styles.descWrapper}>
              <p className={styles.desc}>
                Vibes will take you from raw video to a polished edit quickly
                with machine learning to auto-magically trim video highlights
                and pair with music.
              </p>
            </div>
            <div className={styles.btnWrapper}>
              <div className={styles.downloadBtns}>
                <a
                  href="https://apps.apple.com/us/app/vibes-video-editor-piccollage/id1444385543"
                  target="_blank"
                  className={styles.btnLink}
                >
                  <Img
                    fixed={nodes.downloadIos}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className={styles.downloadBtn}
                  />
                </a>
              </div>
            </div>
          </article>
        </section>
        <section className={styles.content}>
          <div className={styles.productImageWrapper}>
            <Paper>
              <a href="https://apps.apple.com/app/id1476511394" target="_blank">
                <Img
                  fluid={nodes.sparkoImg}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className={styles.product}
                />
              </a>
            </Paper>
          </div>
          <article className={styles.productInfoWrapper}>
            <header className={styles.infoHeader}>
              <a
                className={styles.headerLink}
                href="https://apps.apple.com/app/id1476511394"
                target="_blank"
              >
                <Img
                  fluid={nodes.sparkoIcon}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className={styles.logo}
                />
                <h3 className={styles.title}>Sparko</h3>
              </a>
            </header>
            <div className={styles.descWrapper}>
              <p className={styles.desc}>
                Sparko is our newest app! Amp up your photos to transform them
                into animated gifs and videos in seconds with our special
                auto-cutout feature and integrated animations.
              </p>
            </div>
            <div className={styles.btnWrapper}>
              <div className={styles.downloadBtns}>
                <a
                  href="https://apps.apple.com/app/id1476511394"
                  target="_blank"
                  className={styles.btnLink}
                >
                  <Img
                    fixed={nodes.downloadIos}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className={styles.downloadBtn}
                  />
                </a>
              </div>
            </div>
          </article>
        </section>
      </main>
    </App>
  )
}

export default Products
