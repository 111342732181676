import { graphql, useStaticQuery } from 'gatsby'

const queryAllAppsImages = () => {
  const nodes = useStaticQuery(
    graphql`
      query {
        piccollageIcon: file(relativePath: { eq: "piccollage_icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        piccollageImg: file(relativePath: { eq: "piccollage_img.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        picobookIcon: file(relativePath: { eq: "picobook_icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        picobookImg: file(relativePath: { eq: "picobook_img.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        sparkoIcon: file(relativePath: { eq: "sparko_icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        sparkoImg: file(relativePath: { eq: "sparko_img.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        vibesIcon: file(relativePath: { eq: "vibes_icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        vibesImg: file(relativePath: { eq: "vibes_img.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        deckthecardIcon: file(relativePath: { eq: "deckthecard_icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        deckthecardImg: file(relativePath: { eq: "deckthecard_img.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        downloadIos: file(relativePath: { eq: "download_ios.png" }) {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        downloadAndroid: file(relativePath: { eq: "download_android.png" }) {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        downloadWin: file(relativePath: { eq: "download_win.png" }) {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return Object.entries(nodes).reduce((acc, cur) => {
    const key = cur[0]
    const value = cur[1].childImageSharp.fluid || cur[1].childImageSharp.fixed

    acc[key] = value

    return acc
  }, {})
}

export default queryAllAppsImages
